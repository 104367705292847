import React from "react"
import { Row, Col, Container, ListGroup } from "react-bootstrap"

import imgGlobe from '../images/icons/globe-shield.png';
import imgAtom from '../images/icons/atom.png';
import imgPlug from '../images/icons/plug.png';
import imgPointer from '../images/icons/pointer.png';

import Layout from "../components/layoutBlank"
import JumbotronTv from "../components/jumbotron-tv"

const Tv = () => (
	<Layout pageInfo={{ pageName: "index" }}>
		<JumbotronTv pageInfo={{ title: "Q-Net Security", body: "" }} />
	</Layout>
)

export default Tv
