/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"

import Navbar from "./navBar"
import Footer from "./footer"
import FiftyFifty from "./fifty-fifty"


const Layout = ({ children, pageInfo }) => {
	return (
		<>
			<main>{children}</main>
		</>
	)
}

export default Layout