import React from "react"

const JumbotronHome = ({ pageInfo }) => {

	return (

		<div className="jumbotron-home">
			<div className="content">
				<video  playsInline autoPlay muted loop id="bgvideo">
					<source src="/video/homepage-background.mp4" type="video/mp4" />
				</video>
				<div className="bg-transparent background">
					<div className="jumbotron jumbotron-fluid bg-transparent">
						<div className="jumbotron-branding">
							<div className="headline">
								<img src="/images/qnet-logo-stacked.svg"/>
								<div className="sr-only">{ pageInfo.title }</div>
								<div className="subtitle">
									{ pageInfo.body }
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

	)
}

export default JumbotronHome

